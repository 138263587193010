import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ConsultCardIconsProps, IconProps, Icons } from "../../types/components/queue/consultCardIconsProps";

export function PhosphorIcons(props: IconProps): JSX.Element {
    let iconName = props.name;
    const Icon = Icons[iconName as keyof typeof Icons];
    return <Icon size="19" />;
}

const ConsultCardIcons = (props: ConsultCardIconsProps) => {
    return (
        <div className="flex white-space-nowrap">
            {props.methods.getIcons().map((eachField) => {
                if (eachField.icon || eachField.label) {
                    return (
                        <div className="flex align-items-center mr-2" key={uuidv4()}>
                            {eachField.icon && <PhosphorIcons name={eachField.icon} key={uuidv4()} />}
                            {eachField.label && (
                                <span className="text-sm font-normal ml-1" style={{ textTransform: "capitalize" }}>
                                    {eachField.label}
                                </span>
                            )}
                        </div>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
};

export default ConsultCardIcons;
