import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { ConsultCardButtonProps } from "../../types/components/queue/consultCardButtonProps";
import { ConsultInteractionModeEnum } from "../../types/consult-api";
import { isConsultInProgress } from "../../utils";
import { AcceptSyncPhoneConsultModal, AcceptSyncVideoConsultModal } from "./QueueModals";

const ConsultCardButton = (props: ConsultCardButtonProps) => {
    const [loading, setLoading] = useState(false);
    const [showAcceptSyncVideoConsult, setShowAcceptSyncVideoConsult] = useState(false);
    const [showAcceptSyncPhoneConsult, setShowAcceptSyncPhoneConsult] = useState(false);
    const toast = useRef<Toast | null>(null);

    const cta = props.methods.getCTA(toast);
    const isInProgress = isConsultInProgress(props.consult);
    return (
        <>
            <Toast ref={toast} onHide={() => {}} style={{ opacity: "1" }} />
            <div className={"consult-card-button"}>
                <Button
                    onClick={async () => {
                        if (cta.label === "Accept") {
                            if (props.consult.interactionMode === ConsultInteractionModeEnum.VideoChat) {
                                setShowAcceptSyncVideoConsult(true);
                                return;
                            } else if (props.consult.interactionMode === ConsultInteractionModeEnum.PhoneCall) {
                                setShowAcceptSyncPhoneConsult(true);
                                return;
                            }
                        }

                        setLoading(true);
                        await cta.onClickFn();
                        setLoading(false);
                    }}
                    disabled={loading || (props.disabled && !isInProgress)}
                    label={cta.label}
                    loading={loading}
                    className={"p-button p-button-sm"}
                    tooltip={props.disabled && !isInProgress ? "Too many consults in progress" : ""}
                    tooltipOptions={{ showOnDisabled: true, position: "top" }}
                ></Button>
            </div>
            {showAcceptSyncVideoConsult && (
                <AcceptSyncVideoConsultModal
                    isVisible={showAcceptSyncVideoConsult}
                    cancelFn={() => setShowAcceptSyncVideoConsult(false)}
                    completeFn={async () => {
                        await cta.onClickFn();
                        setShowAcceptSyncVideoConsult(false);
                    }}
                />
            )}
            {showAcceptSyncPhoneConsult && (
                <AcceptSyncPhoneConsultModal
                    isVisible={showAcceptSyncPhoneConsult}
                    cancelFn={() => setShowAcceptSyncPhoneConsult(false)}
                    completeFn={async () => {
                        await cta.onClickFn();
                        setShowAcceptSyncPhoneConsult(false);
                    }}
                />
            )}
        </>
    );
};

export default ConsultCardButton;
