import { Button } from "primereact/button";
import React from "react";
import { Consult } from "../../types/consult-api";

interface ConsultDebuggerProps {
    consult: Consult;
}

const ConsultDebugger = ({ consult }: ConsultDebuggerProps) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify(consult, null, 2));
    };

    return (
        <div style={{ marginTop: "1rem" }}>
            <Button className="p-button-sm" onClick={copyToClipboard}>
                Copy All Text
            </Button>
            <pre>{JSON.stringify(consult, null, 2)}</pre>
        </div>
    );
};

export default ConsultDebugger;
