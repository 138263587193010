import { Card } from "primereact/card";
import React from "react";

const NoConsultCard = (props: { text: string; image?: string }) => {
    return (
        <>
            <Card style={{ marginBottom: "16px", minHeight: "143px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "16px" }}>
                <p className={"text-sm font-normal"} style={{ textAlign: "center" }}>
                    {props.text}
                </p>

                {props.image && (
                    <div style={{ textAlign: "center", padding: "1.25rem 0" }}>
                        <img src={`${props.image}`} alt="" style={{ maxWidth: "100%", maxHeight: "400px" }} />
                    </div>
                )}
            </Card>
        </>
    );
};

export default NoConsultCard;
