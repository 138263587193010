import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { QueryClient, useQuery } from "react-query";
import { ClinicianApi } from "../types/clinician-api";
import { Patient } from "../types/emr-api";

const patientsFilterKey = "patientsFilterKey";

interface Response {
    body: Record<string, Patient>; // Guids to patient mapping, Patient can have multiple episodes
    pendingPatients: Record<string, (patient: Patient) => void>; // Pending patients
}

const queryClient = new QueryClient();

/**
 * Fetch the patients from the server. This function is debounced to avoid multiple
 * calls in a short period of time
 */
const callbackFetchPatients = (): void => {
    const cachedResponse: any = queryClient.getQueryData(patientsFilterKey) ?? { body: {}, pendingPatients: {} };
    const params: any = { episodeGuids: Object.keys(cachedResponse.pendingPatients) };
    const client = new ClinicianApi();

    client.patient
        .patientFilterUpdate(params)
        .then((response) => {
            const responseBody = response?.data ?? [];

            // @ts-ignore
            responseBody.forEach((patient) => {
                for (const episodeGuid of patient.episodeGuids) {
                    cachedResponse.body[episodeGuid] = patient; // Add the patient to the cache
                    if (cachedResponse.pendingPatients[episodeGuid]) {
                        // If the patient is pending, call the callback
                        cachedResponse.pendingPatients[episodeGuid](patient);
                        delete cachedResponse.pendingPatients[episodeGuid];
                    }
                }
            });

            queryClient.setQueryData(patientsFilterKey, cachedResponse);
        })
        .catch((error) => {
            console.error("Error fetching patients", error);
        });
};

const debouncedFetchPatients = debounce(callbackFetchPatients, 200);

/**
 * Add the guid to the pending list for fetching the patients. If the patient is already fetched, return the patient
 *
 * @param guid
 * @param setPatient
 */
const fetchPatients = async (guid: string, setPatient: any): Promise<Response> => {
    const cachedResponse: any = queryClient.getQueryData(patientsFilterKey) ?? { body: {}, pendingPatients: {} };

    if (!guid || guid.length == 0) {
        // Invalid guid
        return cachedResponse;
    }

    if (cachedResponse.body[guid]) {
        // Patient already fetched
        setPatient(cachedResponse.body[guid]);
        return cachedResponse;
    }

    cachedResponse.pendingPatients[guid] = setPatient; // Add to pending list
    queryClient.setQueryData(patientsFilterKey, cachedResponse); // Update the cache

    debouncedFetchPatients(); // Fetch the patients
    return cachedResponse;
};

const usePatientsFilter = (
    guid: string
): {
    patient: any;
    isLoading: boolean;
    error: unknown;
} => {
    const [patient, setPatient] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>((guid && guid.length > 0) === true);
    const { data, error } = useQuery([patientsFilterKey, guid], () => fetchPatients(guid, setPatient), {
        staleTime: 30 * 60 * 1000, // 30 minutes
        cacheTime: 60 * 60 * 1000, // 60 minutes
    });

    useEffect(() => {
        if (Object.keys(patient).length > 0) {
            setIsLoading(false);
        }

        if (data && data.body && data.body[guid]) {
            setPatient(data.body[guid]);
        }
    }, [patient, data]);

    return {
        patient,
        isLoading,
        error,
    };
};

export default usePatientsFilter;
