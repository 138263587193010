import { Skeleton } from "primereact/skeleton";
import React from "react";
import useCareTeamTaskFilter from "../../hooks/useCareTeamTaskFilter";
import { Consult } from "../../types/consult-api";

const ConsultCardCareTeamTaskInformation = (props: { episodeGuid: string; consult: Consult }) => {
    const { careTeamTask, isLoading, error } = useCareTeamTaskFilter(props.episodeGuid);

    const noProvidedReason = (obj: object) => Object.keys(obj).length === 0;

    const formatCareTeamTaskReason = (value: string) => {
        return value
            ?.split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
    };
    const formattedCareTeamTaskReason = formatCareTeamTaskReason(careTeamTask.reason);

    const showCareTeamTask = careTeamTask && !noProvidedReason(careTeamTask);

    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            {showCareTeamTask && (
                <div className={"ma-task-indicator"}>
                    {isLoading ? (
                        <Skeleton height="18px" borderRadius="4px" />
                    ) : (
                        <p className={"text-sm font-semibold ma-task-text"}>{formattedCareTeamTaskReason}</p>
                    )}
                </div>
            )}
            {props.consult.hasMaWork && <div className={"ma-task-indicator"}>{<p className={"text-sm font-semibold ma-task-text"}>Has MA Work</p>}</div>}
        </div>
    );
};

export default ConsultCardCareTeamTaskInformation;
