import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { QueryClient, useQuery } from "react-query";
import { ClinicianApi } from "../types/clinician-api";
import { CareTeamTask } from "../types/emr-api";

const careTeamTaskFilterKey = "careTeamTaskFilterKey";

interface Response {
    body: Record<string, CareTeamTask>; // Guids to care team task mapping
    pendingCareTeamTask: Record<string, (careTeamTask: CareTeamTask) => void>; // Pending care team task
}

const queryClient = new QueryClient();

/**
 * Fetch the patients from the server. This function is debounced to avoid multiple
 * calls in a short period of time
 */
const callbackFetchCareTeamTask = (): void => {
    const cachedResponse: any = queryClient.getQueryData(careTeamTaskFilterKey) ?? { body: {}, pendingCareTeamTask: {} };
    const client = new ClinicianApi();

    client.careTeamTask
        .careTeamTaskFilterList(Object.keys(cachedResponse.pendingCareTeamTask))
        .then((response) => {
            const responseBody = response?.data ?? [];
            // @ts-ignore
            responseBody.forEach((careTeamTask) => {
                const episodeGuid = careTeamTask.episodeGuid;
                cachedResponse.body[episodeGuid] = careTeamTask; // Add the patient to the cache
                if (cachedResponse.pendingCareTeamTask[episodeGuid]) {
                    // If the patient is pending, call the callback
                    cachedResponse.pendingCareTeamTask[episodeGuid](careTeamTask);
                    delete cachedResponse.pendingCareTeamTask[episodeGuid];
                }
            });

            queryClient.setQueryData(careTeamTaskFilterKey, cachedResponse);
        })
        .catch((error) => {
            console.error("Error fetching care team task", error);
        });
};

const debouncedFetchCareTeamTask = debounce(callbackFetchCareTeamTask, 200);

/**
 * Add the guid to the pending list for fetching the patients. If the patient is already fetched, return the patient
 *
 * @param guid
 * @param setPatient
 */
const fetchCareTeamTask = async (guid: string, setCareTeamTask: any): Promise<Response> => {
    const cachedResponse: any = queryClient.getQueryData(careTeamTaskFilterKey) ?? { body: {}, pendingCareTeamTask: {} };

    if (!guid || guid.length == 0) {
        // Invalid guid
        return cachedResponse;
    }

    if (cachedResponse.body[guid]) {
        // Patient already fetched
        setCareTeamTask(cachedResponse.body[guid]);
        return cachedResponse;
    }

    cachedResponse.pendingCareTeamTask[guid] = setCareTeamTask; // Add to pending list
    queryClient.setQueryData(careTeamTaskFilterKey, cachedResponse); // Update the cache

    debouncedFetchCareTeamTask();
    return cachedResponse;
};

const useCareTeamTaskFilter = (
    guid: string
): {
    careTeamTask: any;
    isLoading: boolean;
    error: unknown;
} => {
    const [careTeamTask, setCareTeamTask] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>((guid && guid.length > 0) === true);
    const { data, error } = useQuery([careTeamTaskFilterKey, guid], () => fetchCareTeamTask(guid, setCareTeamTask), {
        staleTime: 30 * 60 * 1000, // 30 minutes
        cacheTime: 60 * 60 * 1000, // 60 minutes
    });

    useEffect(() => {
        if (Object.keys(careTeamTask).length > 0) {
            setIsLoading(false);
        }

        if (data && data.body && data.body[guid]) {
            setCareTeamTask(data.body[guid]);
        }
    }, [careTeamTask, data]);

    return {
        careTeamTask,
        isLoading,
        error,
    };
};

export default useCareTeamTaskFilter;
