import { Skeleton } from "primereact/skeleton";
import React from "react";
import usePatientsFilter from "../../hooks/usePatientsFilter";
import { ConsultCardProps } from "../../types/components/queue/consultCardProps";
import calculateAge from "../../utils/calculateAge";

const ConsultCardPatientInformation = (props: ConsultCardProps) => {
    const episodeGuid: string = props.consult.episodeGuid || "";
    const { patient, isLoading, error } = usePatientsFilter(episodeGuid);

    const patientName = `${patient.firstName ? patient.firstName : "External Patient"} ${patient.lastName ? patient.lastName : ""}`;
    const gender = patient.gender ? patient.gender.substring(0, 1).toUpperCase() : "";
    const age = patient.birthDate ? calculateAge(patient.birthDate) : "";

    return (
        <div className={"patient"}>
            <div className={"text-sm font-normal patient-name"}>
                <strong>{isLoading ? <Skeleton height="18px" borderRadius="4px" /> : patientName}</strong>
            </div>
            <div className={"text-sm font-normal patient-info"}>
                {isLoading ? <Skeleton height="18px" width={"40%"} borderRadius="4px" /> : `${gender ? gender : ""} ${gender ? "⋅" : ""} ${age ? age : ""}`}
            </div>
        </div>
    );
};

export default ConsultCardPatientInformation;
